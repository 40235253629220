import React, { useEffect, useState } from 'react';
import { Container, Typography, List, ListItem, Divider, Box, Grid } from '@mui/material';
import supabase from '../../utils/supabaseClient'; // Supabase-klient
import { Helmet } from 'react-helmet-async'; // 🔹 SEO


const RatingLeaderboard = () => {
  const [topImages, setTopImages] = useState([]);

  // 🔹 Funktion för att hämta bilder och deras genomsnittliga betyg från Supabase
  const fetchData = async () => {
    try {
      const { data, error } = await supabase
        .from("plain_rating_average") 
        .select("image_id, title, avg_rating, total_ratings")
        .order("avg_rating", { ascending: false })
        .limit(100);
  
      if (error) {
        console.error("❌ Fel vid hämtning från Supabase:", error);
        return;
      }
  
      // 🔹 Formatera rätt typ
      const formattedData = data.map((row) => ({
        id: row.image_id,
        title: row.title,
        avgRating: Number(row.avg_rating),  // ✅ Säkerställer att det är ett nummer
        totalRatings: row.total_ratings
      }));
  
      setTopImages(formattedData);
    } catch (error) {
      console.error("❌ Ett oväntat fel inträffade:", error);
    }
  };

  // 🔹 Anropa fetchData när komponenten laddas
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
    {/* 🔹 SEO-metadata */}
    <Helmet>
      <title>Topplista: Högst betygsatta varumärken i Sverige | Brand-o-meter</title>
      <meta
        name="description"
        content="Se vilka varumärken som fått högst genomsnittsbetyg av svenska folket. Topplistan baseras på tusentals röster – uppdateras kontinuerligt."
      />
      <meta property="og:title" content="Topprankade varumärken enligt svenska användare" />
      <meta
        property="og:description"
        content="Utforska vilka varumärken som fått högst betyg – enligt tusentals röster i Brand-o-meter."
      />
      <meta property="og:type" content="website" />
    </Helmet>

    <Container maxWidth="md">
      {/* 🔹 Titel */}
      <Typography variant="h1" align="center" gutterBottom>
        Top {topImages.length} - Genomsnittsbetyg
      </Typography>

      {/* 🔹 Lista över toppbilder */}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <List sx={{ width: '100%', maxWidth: '600px' }}>
          {topImages.map((image, index) => (
            <React.Fragment key={image.id}>
              <ListItem>
                <Grid container alignItems="center">
                  {/* Placering och namn - vänsterställd */}
                  <Grid item xs={8}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                      {index + 1}. {image.title}
                    </Typography>
                  </Grid>
                  {/* Genomsnittsbetyg - högerställd */}
                  <Grid item xs={4}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                      {image.avgRating !== null ? image.avgRating.toFixed(2) : "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              {index < topImages.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </Container>
    </>
  );
};

export default RatingLeaderboard;