import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Divider,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import UserInfo from './UserInfo';
import useAuth from '../../auth/useAuth';
import useMenuItems from './MenuItems';

const AppBarWithDrawer = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const menuItems = useMenuItems();

  return (
    <>
      <AppBar position="static" color="primary">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
          {/* Menyikon (vänster) */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setDrawerOpen(true)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>

          {/* Logga (centrerad) */}
          <Box sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
            <Box
              component="img"
              src="/images/framework/frame-work-brand-o-meter-logo.svg"
              alt="Logo"
              sx={{ height: { xs: 20, sm: 25, md: 30 }, width: 'auto' }}
            />
          </Box>

          {/* Inloggningsknapp (höger) */}
          <Box sx={{ ml: 'auto' }}>
            <UserInfo />
          </Box>
        </Toolbar>
      </AppBar>

      {/* Drawer-meny */}
      <Drawer anchor="left" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Box sx={{ width: 250 }} role="presentation" onClick={() => setDrawerOpen(false)}>
          <List>
            {menuItems.map((item, index) =>
              item.isDivider ? (
                <React.Fragment key={index}>
                  <Divider />
                  {/* Om vi vill ha rubrik för sektionen */}
                  <ListItem>
                    <ListItemText
                      primary={item.text}
                      sx={{ textAlign: 'center', fontWeight: 'bold', color: 'gray' }}
                    />
                  </ListItem>
                </React.Fragment>
              ) : (
                <ListItem
                  component="button"
                  key={item.text}
                  onClick={() => {
                    if (window.gtag) {
                      window.gtag('event', 'menu_click', {
                        event_category: 'navigation',
                        event_label: item.text,
                        path: item.path,
                        user_role: user?.role ?? 'guest',
                      });
                    }
                    navigate(item.path);
                  }}
                >
                  <ListItemText primary={item.text} />
                </ListItem>
              )
            )}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default AppBarWithDrawer;