import React, { useEffect, useState } from 'react';
import { Container, Typography, List, ListItem, Divider, Box, Grid, MenuItem, Select } from '@mui/material';
import supabase from '../../utils/supabaseClient';
import { Helmet } from 'react-helmet-async';

const LeaderboardAverage = () => {
  const [topPlayers, setTopPlayers] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState('all');

  // 🔹 Hämta alla businesses för dropdown
  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        const { data, error } = await supabase
          .from('business')
          .select('id, name')
          .order('name', { ascending: true });  // 📌 Lägg till denna rad för att sortera i backend
        if (error) throw error;
        setBusinesses(data);
      } catch (error) {
        console.error('❌ Fel vid hämtning av businesses:', error);
      }
    };
    fetchBusinesses();
  }, []);

  // 🔹 Hämta topplistan baserat på glidande medelvärde och vald business
  const fetchAverageLeaderboard = async () => {
    try {
      const { data, error } = await supabase.rpc('get_leaderboard_moving_avg', {
        p_business_id: selectedBusiness === 'all' ? null : selectedBusiness
      });

      if (error) throw error;

      setTopPlayers(data);
    } catch (error) {
      console.error('❌ Fel vid hämtning från Supabase:', error);
    }
  };

  // 🔹 Kör funktionen vid inladdning och vid ändring av business
  useEffect(() => {
    fetchAverageLeaderboard();
  }, [selectedBusiness]);

  return (
    <>
      <Helmet>
        <title>Sveriges populäraste varumärken – Topplista baserat på dueller | Brand-o-meter</title>
        <meta
          name="description"
          content="Se vilka varumärken som rankas högst i Sverige just nu! Denna topplista bygger på ett glidande medelvärde av de 20 senaste ELO-duellerna – en stabil indikator på långsiktig popularitet."
        />
        <meta property="og:title" content="Topplista: Sveriges populäraste varumärken" />
        <meta
          property="og:description"
          content="Upptäck vilka varumärken som presterar bäst i ELO-dueller. Baserat på glidande medelvärde – från Brand-o-meter."
        />
        <meta property="og:type" content="website" />
      </Helmet>

      <Container maxWidth="md">
        {/* 🔹 Dropdown för att välja business */}
        <Box sx={{ mb: 2 }}>
          <Select
            value={selectedBusiness}
            onChange={(e) => setSelectedBusiness(e.target.value)}
            displayEmpty
            fullWidth
          >
            <MenuItem value="all">Visa alla</MenuItem>
            {businesses.map((business) => (
              <MenuItem key={business.id} value={business.id}>{business.name}</MenuItem>
            ))}
          </Select>
        </Box>

        {/* 🔹 Titel */}
        <Typography variant="h2" align="center" gutterBottom>
          Sveriges {topPlayers.length} populäraste varumärken - dueller
        </Typography>

        {/* 🔹 Lista över toppbilder */}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <List sx={{ width: '100%', maxWidth: '600px' }}>
            {topPlayers.map((player, index) => (
              <React.Fragment key={player.image_id}>
                <ListItem>
                  <Grid container alignItems="center">
                    {/* Placering och titel */}
                    <Grid item xs={8}>
                      <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                        {index + 1}. {player.title ?? `Bild-ID: ${player.image_id.slice(0, 8)}`}
                      </Typography>
                    </Grid>

                    {/* Genomsnittlig ELO */}
                    <Grid item xs={4}>
                      <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                        {player.latest_moving_avg?.toFixed(0) ?? '—'}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                {index < topPlayers.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Box>
        {/* 🔹 Info-ruta under listan */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="body1" align="center">
            Topplistan visar ett glidande medelvärde från respektive varumärkes tjugo senaste dueller. Anledningen är att vid en duell kan ett varumärke flytta så mycket som tio placeringar. En lista med varumärkenas nuvarande ELO-poäng ger därför en volatil och flyktig ögonblicksbild. Det glidande medelvärdet ger långsammare förändringar och jämnar ut eventuella statistiska avvikelser.
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default LeaderboardAverage;