import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
} from "@mui/material";
import supabase from "../../utils/supabaseClient";
import RatingChart from "./RatingChart";
import { useParams, useNavigate } from 'react-router-dom';
import ImageTitleList from '../../components/shared/ImageTitleList';
import { Helmet } from 'react-helmet-async';



const RatingChartPage = () => {
  const [images, setImages] = useState([]);
  const [selectedImageId, setSelectedImageId] = useState("");
  const [ratings, setRatings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedGender, setSelectedGender] = useState("all"); // 🔹 Dropdown för kön
  const [selectedAge, setSelectedAge] = useState("all"); // 🔹 Dropdown för ålder
  const { slug } = useParams();
  const navigate = useNavigate();

  const [selectedSlug, setSelectedSlug] = useState(slug || '');

  // 🔹 Hämta bilder vid sidladdning
  useEffect(() => {
    fetchImages();
  }, []);

  // 🔹 Synka selectedSlug med URL:ens slug
  useEffect(() => {
    setSelectedSlug(slug || '');
  }, [slug]);

  // 🔹 Sätt rätt imageId baserat på slug
  useEffect(() => {
    const image = images.find((img) => img.slug === slug);
    if (image) {
      setSelectedImageId(image.id);
    }
  }, [images, slug]);

  // 🔹 Hämta betyg baserat på imageId och filter
  useEffect(() => {
    if (selectedImageId) {
      fetchRatings(selectedImageId, selectedGender, selectedAge);
    }
  }, [selectedImageId, selectedGender, selectedAge]);

  const fetchImages = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase.from("images").select("id, title, url, slug");
      if (error) throw error;

      // ✅ Sortera bilderna i bokstavsordning efter `title`
      const sortedData = data.sort((a, b) => a.title.localeCompare(b.title));

      setImages(sortedData);
    } catch (error) {
      console.error("❌ Fel vid hämtning av bilder:", error);
    } finally {
      setLoading(false);
    }
  };


  const fetchRatings = async (imageId, gender = "all", ageGroup = "all") => {
    setLoading(true);
    try {
      const { data, error } = await supabase.rpc("get_rating_distribution", {
        p_image_id: imageId,
        p_gender: gender,
        p_age_group: ageGroup,
      });

      if (error) throw error;
      setRatings(data);
    } catch (error) {
      console.error("❌ Fel vid hämtning av betyg:", error);
    } finally {
      setLoading(false);
    }
  };

  const selectedImage = images.find((img) => img.id === selectedImageId);

  return (
    <>
      <Helmet>
        <title>
          {selectedImage
            ? `Betygsfördelning för ${selectedImage.title} – Sveriges populäraste varumärken`
            : 'Betygsfördelning – Sveriges populäraste varumärken'}
        </title>

        <meta
          name="description"
          content={
            selectedImage
              ? `Detaljerad statistik för ${selectedImage.title}. Se hur svenska konsumenter betygsatt varumärket över kön och åldersgrupp.`
              : 'Upptäck vilka varumärken som fått högst betyg av svenska konsumenter. Se statistik per varumärke, kön och åldersgrupp.'
          }
        />

        {/* Keywords */}
        <meta
          name="keywords"
          content={
            selectedImage
              ? `${selectedImage.title}, betyg, statistik, varumärken, konsumentundersökning`
              : 'betygsfördelning, varumärken, konsumentbetyg, populära varumärken, Sverige'
          }
        />

        {/* Open Graph */}
        <meta property="og:title" content={selectedImage ? `Betygsfördelning för ${selectedImage.title}` : 'Betygsfördelning – Varumärken'} />
        <meta property="og:description" content="Detaljerad betygsstatistik för Sveriges populäraste varumärken. Filtrera per kön och åldersgrupp." />
        {selectedImage?.url && <meta property="og:image" content={selectedImage.url} />}
        <meta property="og:type" content="website" />
        {selectedImage && <meta property="og:image:alt" content={`Logotyp för ${selectedImage.title}`} />}
      </Helmet>

      <Container maxWidth="lg">
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              Betygsfördelning
            </Typography>
          </Grid>

          {/* Dropdowns */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Välj ett varumärke</InputLabel>
              <Select
                value={images.some((img) => img.slug === selectedSlug) ? selectedSlug : ''}
                onChange={(e) => {
                  setSelectedSlug(e.target.value);
                  navigate(`/ratings-chart/${e.target.value}`); // 🔗 Dynamisk länk
                }}
              >
                {images.map((img) => (
                  <MenuItem key={img.id} value={img.slug}>
                    {img.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Kön</InputLabel>
              <Select value={selectedGender} onChange={(e) => setSelectedGender(e.target.value)}>
                <MenuItem value="all">Visa alla</MenuItem>
                <MenuItem value="male">Man</MenuItem>
                <MenuItem value="female">Kvinna</MenuItem>
                <MenuItem value="unknown">Okänt</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Åldersgrupp</InputLabel>
              <Select
                value={selectedAge}
                onChange={(e) => setSelectedAge(e.target.value)}
                fullWidth
              >
                <MenuItem value="all">Visa alla</MenuItem>
                <MenuItem value="under_18">Under 18 år</MenuItem>
                <MenuItem value="18_24">18–24 år</MenuItem>
                <MenuItem value="25_34">25–34 år</MenuItem>
                <MenuItem value="35_44">35–44 år</MenuItem>
                <MenuItem value="45_54">45–54 år</MenuItem>
                <MenuItem value="55_64">55–64 år</MenuItem>
                <MenuItem value="65_plus">65 år och äldre</MenuItem>
                <MenuItem value="unknown">Okänt</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Bild + Betygsdata i två spalter */}
          {selectedImageId && (
            <Grid container item xs={12} spacing={4} alignItems="center">
              {/* 📷 Bild till vänster */}
              <Grid item xs={12} md={6}>
                <Box textAlign="center">
                  <img
                    src={selectedImage?.url}
                    alt={`Logotyp för ${selectedImage?.title}`}
                    title={`Logotyp för ${selectedImage?.title}`}
                    loading="lazy"
                    style={{
                      width: "100%",
                      maxWidth: "350px",
                      height: "auto",
                      borderRadius: 8,
                    }}
                  />

                  <Typography variant="h6" sx={{ fontWeight: "bold", mt: 1 }}>
                    {selectedImage?.title}
                  </Typography>
                </Box>
              </Grid>

              {/* 📊 Betygsstaplar till höger */}
              <Grid item xs={12} md={6}>
                {loading ? (
                  <Box display="flex" justifyContent="center">
                    <CircularProgress />
                  </Box>
                ) : ratings ? (
                  <RatingChart ratingsData={ratings} />
                ) : (
                  <Typography variant="body1" align="center">
                    Ingen betygsdata tillgänglig.
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>

      <ImageTitleList
        images={images}
        onSelect={(slug) => navigate(`/ratings-chart/${slug}`)}
      />
    </>
  );
};

export default RatingChartPage;