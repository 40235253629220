import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  CircularProgress,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from '@mui/material';
import supabase from '../../utils/supabaseClient';
import { Helmet } from 'react-helmet-async';

import ImageTitleList from '../../components/shared/ImageTitleList';

function countWords(words) {
  return words.reduce((map, word) => {
    map[word.word] = (map[word.word] || 0) + word.frequency;
    return map;
  }, {});
}

function WordCloud({ words }) {
  const wordCounts = countWords(words);
  const maxCount = Math.max(...Object.values(wordCounts), 1);

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center' }}>
      {Object.entries(wordCounts).map(([word, count]) => (
        <span
          key={word}
          style={{
            fontSize: `${((count / maxCount) * 5.8) + 0.6}em`,
            fontWeight: 'bold',
            color: '#333',
          }}
        >
          {word}
        </span>
      ))}
    </div>
  );
}

const WordCloudPage = () => {
  const { slug } = useParams();
  const navigate = useNavigate();

  const [images, setImages] = useState([]);
  const [selectedSlug, setSelectedSlug] = useState(slug || '');
  const [image, setImage] = useState(null);
  const [words, setWords] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAllImages = async () => {
      const { data, error } = await supabase
        .from('images')
        .select('id, title, url, slug')
        .order('title', { ascending: true });

      if (error) {
        console.error('❌ Fel vid hämtning av bilder:', error);
      } else {
        setImages(data);
      }
    };

    fetchAllImages();
  }, []);

  useEffect(() => {
    if (!slug) {
      setImage(null);
      setWords([]);
      setLoading(false);
      return;
    }

    const fetchImageAndWords = async () => {
      setLoading(true);
      try {
        const { data: imageData, error: imageError } = await supabase
          .from('images')
          .select('id, title, url')
          .eq('slug', slug)
          .single();

        if (imageError || !imageData) {
          throw imageError || new Error('Ingen bild hittades');
        }

        setImage(imageData);

        const { data: wordData, error: wordError } = await supabase
          .from('word_statistics')
          .select('word, frequency')
          .eq('image_id', imageData.id);

        if (wordError) throw wordError;
        setWords(wordData);
      } catch (err) {
        console.error('❌ Fel vid hämtning:', err);
        setImage(null);
        setWords([]);
      } finally {
        setLoading(false);
      }
    };

    fetchImageAndWords();
  }, [slug]);

  // 🔹 Synka selectedSlug med slug från URL
  useEffect(() => {
    setSelectedSlug(slug || '');
  }, [slug]);

  return (
    <>
      <Helmet>
        <title>
          {image?.title
            ? `Ordmoln för ${image.title} – Vad tycker svenskar om ${image.title}?`
            : 'Ordmoln över Sveriges populäraste varumärken – Brand-o-meter'}
        </title>
        <meta
          name="description"
          content={
            image?.title
              ? `Se vad svenskar associerar med ${image.title}. Baserat på tusentals röster från användare.`
              : 'Analys och insikter vad svenska folket tycker om Sveriges mest populära varumärken. Ordmoln baserade på riktiga associationer.'
          }
        />
        <meta property="og:title" content={image?.title ? `Ordmoln för ${image.title}` : 'Ordmoln över varumärken'} />
        <meta property="og:description" content="Brand-o-meter visar vad svenskar associerar med olika varumärken." />
        <meta property="og:image" content={image?.url} />
        <meta property="og:type" content="website" />
      </Helmet>

      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              {slug && image ? `Ordmoln för ${image.title}` : 'Välj ett varumärke'}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Välj en bild</InputLabel>
              <Select
                value={
                  images.some((img) => img.slug === selectedSlug) ? selectedSlug : ''
                }
                onChange={(e) => {
                  setSelectedSlug(e.target.value);
                  navigate(`/wordcloud/${e.target.value}`);
                }}
              >
                {images.map((image) => (
                  <MenuItem key={image.id} value={image.slug}>
                    {image.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {loading && (
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <CircularProgress />
            </Grid>
          )}

          {image && (
            <>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <img
                  src={image.url}
                  alt={`Logotyp för ${image.title}`}
                  title={`Logotyp för ${image.title}`}
                  loading="lazy"
                  style={{ width: '100%', maxWidth: '300px', height: 'auto' }}
                />
              </Grid>
              <Grid item xs={12}>
                {words.length > 0 ? <WordCloud words={words} /> : 'Inga ord tillgängliga.'}
              </Grid>
            </>
          )}
        </Grid>
      </Container>

      {/* 🔹 Fullbreddstitellista placerad utanför Container */}
      <ImageTitleList
        images={images}
        onSelect={(slug) => navigate(`/wordcloud/${slug}`)}
      />
    </>
  );
};

export default WordCloudPage;
