import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { initGA } from '../../utils/analytics';

const CookieConsent = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent === 'accepted') {
      initGA();
    } else if (!consent) {
      setVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    initGA();
    setVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'declined');
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: 'grey.900',
        color: 'white',
        px: 2,
        py: 2,
        zIndex: 1300,
      }}
    >
      <Box
        sx={{
          maxWidth: '1200px',
          mx: 'auto',
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: { xs: 'flex-start', sm: 'center' },
          gap: 2,
        }}
      >
        <Typography sx={{ flex: 1 }}>
          Brand-o-meter använder cookies en bättre användarupplevelse och för analysera trafik.
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 1,
            width: { xs: '100%', sm: 'auto' },
          }}
        >
          <Button
            onClick={handleDecline}
            variant="text"
            color="inherit"
            sx={{ fontWeight: 400 }}
            fullWidth
          >
            Avvisa
          </Button>
          <Button
            onClick={handleAccept}
            variant="contained"
            color="success"
            sx={{ fontWeight: 600 }}
            fullWidth
          >
            Acceptera
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CookieConsent;